import socketIoAPI from '@/modules/socketAPI.js'
import vueAPI from '@/modules/vueAPI.js'
import dataAPI from 'vue-data-api'

export default {
    state: {
        cartData: [
            // {
            //     product_id: 1,
            //     image: null,
            //     variation_id: 1,
            //     product_name: 'Diamond Selection',
            //     product_price: 100,
            //     product_quantity: 2,
            //     product_weight: 50,
            //     max_quantity: 5,
            // },
            // {
            //     product_id: 2,
            //     image: null,
            //     variation_id: 1,
            //     product_name: 'Silver Selection',
            //     product_price: 300,
            //     product_quantity: 1,
            //     product_weight: 150,
            //     max_quantity: 5,
            // },
            // {
            //     product_id: 3,
            //     image: null,
            //     variation_id: 1,
            //     product_name: 'Silver Selection',
            //     product_price: 300,
            //     product_quantity: 1,
            //     product_weight: 150,
            //     max_quantity: 5,
            // },
            // {
            //     product_id: 4,
            //     image: null,
            //     variation_id: 1,
            //     product_name: 'Silver Selection',
            //     product_price: 300,
            //     product_quantity: 1,
            //     product_weight: 150,
            //     max_quantity: 5,
            // },
            // {
            //     product_id: 5,
            //     image: null,
            //     variation_id: 1,
            //     product_name: 'Silver Selection',
            //     product_price: 300,
            //     product_quantity: 1,
            //     product_weight: 150,
            //     max_quantity: 5,
            // },
            // {
            //     product_id: 6,
            //     image: null,
            //     variation_id: 1,
            //     product_name: 'Silver Selection',
            //     product_price: 300,
            //     product_quantity: 1,
            //     product_weight: 150,
            //     max_quantity: 5,
            // }
        ]
    },
    getters: {
    },
    mutations: {
        restoreCartData(state, data) {
            state.cartData = data
        },
        removeThisCartItem(state, payload) {
            let index = state.cartData.findIndex(item => item.product_id === payload.product_id)
            if (index !== -1) {
                state.cartData.splice(index, 1)
            }
        },
        addCartItem(state, payload) {
            let index = state.cartData.findIndex(item => {
                if (item.id === payload.product_id && item.variation_id == payload.variation_id) {
                    return true
                }

                return false
            })
            if (index !== -1) {
                if ((state.cartData[index].product_quantity + payload.quantity) > state.cartData[index].max_quantity) {
                    state.cartData[index].product_quantity = state.cartData[index].max_quantity
                } else {
                    state.cartData[index].product_quantity += payload.quantity
                }
            } else {
                state.cartData.push({
                    product_id: payload.id,
                    image: payload.image,
                    variation_id: payload.variation_id,
                    product_name: payload.name,
                    product_price: payload.price,
                    product_quantity: payload.quantity,
                    product_weight: payload.weight,
                    max_quantity: payload.max_quantity,
                })
            }

            dataAPI.callMethod('toggleCart', true)
        }
    },
    actions: {
        removeCartItem: function(context, payload) {
            context.commit('removeThisCartItem', payload)
        }
    },
}

vueAPI.onMounted((thisVue) => {
    if (localStorage.getItem('cartData')) {
        thisVue.$store.commit('restoreCartData', JSON.parse(localStorage.getItem('cartData')))
    }

    thisVue.$watch('$store.state.myCart.cartData', {
        handler: (newVal, oldVal) => {
            // console.log(newVal)
            if (newVal.length > 0) {
                localStorage.setItem('cartData', JSON.stringify(newVal))
            } else {
                localStorage.removeItem('cartData')
            }
        },
        deep: true
    })
})