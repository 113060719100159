export default {
  "header": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home FR"])},
    "journal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journal FR"])},
    "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caviar FR"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About FR"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact FR"])}
  },
  "footer": {
    "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])}
  },
  "about": {
    "main_title_wrapper_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un ode à l'Amur"])},
    "welcome_text_paragraf": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr. Teilans, the founder of Amura tells the story that his travels have taught him the breadth and depth of caviar, and all that it has to offer. Through Amura, a Monaco-based caviar brand named after one of the world’s longest and most bountiful rivers, Mr. Teilans intentionally shares his tasteful experiences around the world, with the world. FR"])},
      
    ],
    "info_section": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amura is a reason to bring loved ones together"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We want you to celebrate the small wins year-round, by creating refined"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["experiences out of the simple moments"])},
      
    ]
  },
  "our_mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre mission"])},
  "our_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre histoire"])},
  "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stockage"])},
  "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origine"])},
  "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille"])},
  "select_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la taille"])},
  "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter au panier"])},
  "order_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commander maintenant"])},
  "caviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop now"])},
  "delivery_promo_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fast delivery in Monaco - 24h"])},
  "refrigerated_packaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refrigerated packaging FR"])},
  "secure_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure payment FR"])}
}