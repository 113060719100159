<template>
    <div class="shop_wrapper">
        <h5>amura</h5>
        <div class="caviar_images_wrapper">
            <template v-for="thisProd in products" :key="thisProd.id">
                <router-link :to="`/product/${thisProd.id}`">
                    <span>{{ $t('order_now') }}</span>
                    <div class="caviar_image" v-if="thisProd.images.length > 0" :style="{
                        'background-image': `url(${getMainImage(thisProd.images).image_url})`
                    }"></div>
                </router-link>
            </template>
        </div>
    </div>
    <div class="delivery_info_block_wrapper">
        <div class="text_icon_wrapper">
            <i class="uit uit-shield-check"></i>
            <span class="special_text">{{ $t('secure_payment') }}</span>
        </div>
        <div class="text_icon_wrapper">
            <i class="uit uit-truck"></i>
            <span class="special_text">
                {{ $t('delivery_promo_text') }}
            </span> 
        </div>
        <div class="text_icon_wrapper_borders">
            <i class="uit uit-box"></i>
            <span class="special_text">{{ $t('refrigerated_packaging') }}</span>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.api_getProducts(100, 1, {
            "category": 1,
            "getImages": true
        }).then((response) => {
            this.products = response.products;
        });
    },
    data() {
        return {
            productsLoaded: false,
            products: []
        }
    },
    methods: {
        getMainImage(images) {
            let mainImage = images.find((image) => {
                return image.main == 1;
            });
            return mainImage;
        }
    }
}
</script>

<style>
    @import url('@/styles/css/shopPage.css');
</style>