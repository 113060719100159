<template>
    <div class="header_wrapper">
        <div class="desctop_menu">
            <router-link to="/">
                <div class="header_logo"></div>
            </router-link>
            <div class="header_menu">
                <ul class="header_navigation">
                    <li v-for="(thisRoute, thisRouteIdx) in headerRoutes" :key="thisRouteIdx"
                        :class="{
                            'active': thisRoute.active
                        }"
                        @click="changePath(thisRoute.path)">
                        {{ $t(thisRoute.name) }}
                    </li>
                </ul>
            </div>
            <locales-switcher></locales-switcher>
            <router-link to="/locations">
                <i class="uis uis-map-marker" title="Locations"></i>
            </router-link>
            <div class="shopping_cart_wrapper" v-if="showShoppingCart">
                <i class="uis uis-shopping-bag" v-if="shoppingCartOpened" @click="shoppingCartOpened = false"
                :class="{
                    'loaded': isInShoppingCartAnyItems
                }"></i>
                <i class="uil uil-shopping-bag" v-else @click="shoppingCartOpened = true"
                :class="{
                    'loaded': isInShoppingCartAnyItems
                }"></i>
            </div>
        </div>

        <div class="mobile_menu">
            <div class="mobile_nav_wrapper">
                <router-link to="/">
                    <div class="header_logo"></div>
                </router-link>
                <locales-switcher></locales-switcher>
                <div class="menu_icon" v-if="!shoppingCartOpened" @click="showSideHeader = true">
                    <i class="uis uis-bars"></i>
                </div>
                <router-link to="/locations">
                    <i class="uis uis-map-marker" title="Locations"></i>
                </router-link>
                <div class="shopping_cart_wrapper" v-if="showShoppingCart">
                    <i class="uis uis-shopping-bag" v-if="shoppingCartOpened" @click="shoppingCartOpened = false"
                    :class="{
                        'loaded': isInShoppingCartAnyItems
                    }"></i>
                    <i class="uil uil-shopping-bag" v-else @click="shoppingCartOpened = true"
                    :class="{
                        'loaded': isInShoppingCartAnyItems
                    }"></i>
                </div>
            </div>
            <div class="header_menu">
                <ul class="header_navigation" :class="{
                    'closed': !showSideHeader
                }">
                    <i class="uis uis-multiply" @click="showSideHeader = false"></i>
                    <li v-for="(thisRoute, thisRouteIdx) in headerRoutes" :key="thisRouteIdx"
                        :class="{
                            'active': thisRoute.active
                        }"
                        @click="changePath(thisRoute.path)">
                        {{ $t(thisRoute.name) }}
                    </li>
                    <div class="sidebar_logo"></div>
                    <div class="sidebar_contact">
                        <a href="mailto:amura@amura.mc">
                            <span>amura@amura.mc</span>
                        </a>
                        <div class="socialmedia_icons">
                            <div class="instagram">
                                <a href="https://www.instagram.com/amura_caviar/" target="_blank">
                                    <svg role="img" viewBox="0 0 24 24"><title>Instagram</title><path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"></path></svg>
                                </a>
                            </div>
                            <div class="facebook">
                                <a href="https://m.facebook.com/AmuraCaviar/" target="_blank">
                                    <svg role="img" viewBox="0 0 24 24"><title>Facebook</title><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"></path></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </ul>
            </div>
        </div>

        <shopping-cart :opened="shoppingCartOpened" @toggleState="toggleCartState"></shopping-cart>
    </div>    
</template>

<script>
import shoppingCartVue from '@/views/shoppingCart.vue'
import localesSwitcherVue from '@/components/localesSwitcher.vue'
import dataAPI from 'vue-data-api'

export default {
    name: 'PanelHeader',
    components: {
        shoppingCart: shoppingCartVue,
        localesSwitcher: localesSwitcherVue
    },
    mounted() {
        this.checkCurrentRoute()
        this.checkCheckOutForCheckout()

        dataAPI.onMethod(this, 'toggleCart', (state) => {
            this.toggleCartState(state)
        })
    },
    data() {
        return {
            showShoppingCart: true,
            shoppingCartOpened: false,
            showSideHeader: false,
            headerRoutes: [
                {
                    path: '/',
                    name: 'header.home',
                    active: false
                },
                {
                    path: '/shop',
                    name: 'header.shop',
                    active: false
                },
                {
                    path: '/about',
                    name: 'header.about',
                    active: false
                },
                {
                    path: '/journal',
                    name: 'header.journal',
                    active: false
                },
                {
                    path: '/contact',
                    name: 'header.contact',
                    active: false
                },
                // {
                //     path: '/recepies',
                //     name: 'Recepies',
                //     active: false
                // }
            ]
        }
    },
    methods: {
        toggleCartState(state) {
            if (typeof state !== 'boolean') return
            this.shoppingCartOpened = state
        },
        changePath(path) {
            this.$router.push(path)
        },
        checkCurrentRoute() {
            let currentRoute = this.$router.currentRoute.value.path
            this.headerRoutes.forEach(item => {
                if (item.path == '/') {
                    if (currentRoute == '/') {
                        item.active = true
                    } else {
                        item.active = false
                    }
                } else if (currentRoute.startsWith(item.path)) {
                    item.active = true;
                } else {
                    item.active = false;
                }
            });
        },
        checkCheckOutForCheckout() {
            let currentRoute = this.$router.currentRoute.value.path
            if (currentRoute.startsWith('/checkout')) {
                this.showShoppingCart = false
            } else {
                if (!this.showShoppingCart) this.showShoppingCart = true
            }
        }
    },
    computed: {
        isInShoppingCartAnyItems() {
            return this.$store.state.myCart.cartData.length > 0
        }
    },
    watch: {
        '$router.currentRoute.value.path': function(newValue) {
            this.checkCurrentRoute()
            this.checkCheckOutForCheckout()
            if (this.showSideHeader) {
                this.showSideHeader = false
            }
        }
    }
}
</script>

<style>
    @import url('@/styles/css/panelHeader.css');
</style>