import axios from 'axios';

let apiURL = '/tech/api/';

const axiosConfig = {
    headers: {
        'Content-Type' : 'application/json; charset=utf-8',
        "Access-Control-Allow-Origin": "*",
    }
}

const apiMixin = {
    methods: {
        async api_getProducts(count = 10, page = 1, filters = {}) {
            let response = await axios.post(apiURL + 'getProducts.php', {
                count: count,
                page: page,
                filters: filters
            }, axiosConfig);
            // console.log(response.data);
            return response.data;
        },
        async api_sendOrderFromCart(additionalInfo = {}) {
            let response = await axios.post(apiURL + 'sendOrder.php', {
                ...additionalInfo,
                items: this.$store.state.myCart.cartData
            }, axiosConfig);
            // console.log(response.data);
            return response.data;
        }
    }
}

export default apiMixin;