export default {
  "header": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "journal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journal"])},
    "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caviar"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
  },
  "footer": {
    "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])}
  },
  "about": {
    "main_title_wrapper_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a contemporary ode to Amur river"])},
    "welcome_text_paragraf": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Monaco-based caviar brand, Amura bears the name of one of the world’s longest and most bountiful rivers. Thanks to its passion and unique expertise for caviar, Amura sparked the desire to share memorable experiences with the rest of the world, eventually ingraining it into their vision and mission."])},
      
    ],
    "info_section": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amura is a reason to bring loved ones together"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We want you to celebrate the small wins year-round, by creating refined"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["experiences out of the simple moments"])},
      
    ]
  },
  "our_mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our mission"])},
  "our_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our History"])},
  "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage"])},
  "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin"])},
  "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
  "select_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select size"])},
  "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to cart"])},
  "order_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order now"])},
  "caviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop now"])},
  "delivery_promo_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fast delivery in Monaco - 24h"])},
  "refrigerated_packaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refrigerated packaging"])},
  "secure_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure payment"])}
}