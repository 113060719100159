import socketIoAPI from '@/modules/socketAPI.js'
import vueAPI from '@/modules/vueAPI.js'

// this.$store.dispatch('loginData_checkForLogin');
// this.$store.commit('loginData_checkForLogin');

export default {
    state: {
        adminLoggedIn: false,
        checkingForLogin: true,
        unloginPosition: null
    },
    getters: {
    },
    mutations: {
        unloginAdmin(state) {
            if (!socket || !socket.connected) return
            socket.emit('unloginMeAsAdmin')
        },
        loginAdmin(state, loginData) {
            if (!socket || !socket.connected) return
            socket.emit('adminLogin', loginData, device, function(returnData) {
                if (returnData.response.success == true) {
                    Cookies.set('adlgn_t', returnData.response.sessionKey, { expires: 30 })
                    state.adminLoggedIn = true;
                    vueAPI.onMounted(function(thisVue) {
                        if (state.unloginPosition) {
                            thisVue.$router.push(state.unloginPosition);
                        } else {
                            thisVue.$router.push("/");
                        }
    
                        state.unloginPosition = null;
                    })
                } else {
                    console.log(returnData)
                    state.adminLoggedIn = false;
                }
            })
        }
    },
    actions: {
    },
}

socketIoAPI.onNewConnection(function(socketVar) {
    socketVar.on("connect", () => {
        console.log("Connected to server!");
        let timestampNow = Date.now()
        let thisCookiesLoginToken = Cookies.get('adlgn_t')
        if (thisCookiesLoginToken && thisCookiesLoginToken.length >= 32) {
            socketVar.emit('adminAuth', timestampNow, thisCookiesLoginToken, device, function(returnData) {
                // console.log(`Return data: ${JSON.stringify(returnData)}`)
                vueAPI.onMounted(function(thisVue) {
                    if (returnData.response.success == true) {
                        Cookies.set('adlgn_t', thisCookiesLoginToken, { expires: 30 })
                        thisVue.$store.state.loginData.adminLoggedIn = true
                    } else {
                        thisVue.$store.state.loginData.adminLoggedIn = false
                    }
                    thisVue.$store.state.loginData.checkingForLogin = false
                })
            })
        } else {
            vueAPI.onMounted(function(thisVue) {
                thisVue.$store.state.loginData.adminLoggedIn = false
                thisVue.$store.state.loginData.checkingForLogin = false
            })
        }
    })

    socketVar.on("refreshLoginToken", (callback) => {
        let thisCookiesLoginToken = Cookies.get('adlgn_t')
        if (thisCookiesLoginToken && thisCookiesLoginToken.length >= 32) {
            callback(thisCookiesLoginToken)
        }
    })
})

socketIoAPI.onNewConnection(function(socketVar) {
    socketVar.on('adminSessionLogout', function() {
        console.log('Logging out')
        Cookies.remove('adlgn_t')
        vueAPI.onMounted(function(thisVue) {
            thisVue.$store.state.loginData.adminLoggedIn = false
            thisVue.$store.state.loginData.checkingForLogin = false
            thisVue.$store.state.loginData.unloginPosition = window.location.pathname
            if (!socket || !socket.connected) return
            socket.disconnect()
        })
    })
})