<template>
    <div class="cookies_wrapper-back" v-if="cookiesBanner">
        <div class="cookies_banner_wrapper">
            <div class="cookies_text">
                <div class="cookies_info_wrapper">
                    <span>We use cookies and we explain how Amura uses cookies and similar technologies in connection with the <strong>www.amura.mc</strong> website.</span>
                    <div class="know_cookies_buttons">
                        <button class="cookies_policy" @click="changeCookiesWrapper('are')"
                        :class="{
                            'readMore': cookiesWrapper === 'are',
                        }">
                            <span>Cookies are</span>
                        </button>
                        <button class="cookies_policy" @click="changeCookiesWrapper('types')"
                        :class="{
                            'readMore': cookiesWrapper === 'types',
                        }">
                            <span>Cookies Types</span>
                        </button>
                        <div class="cookies_buttons_wrapper">
                            <!-- <button class="dismiss_cookies" @click="dismissCookies()">
                                <span>Dismiss</span>
                            </button> -->
                            <button class="allow_cookies" @click="readCookies()">
                                <span>OK</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="cookies_explanation_wrapper">
                    <div class="c_info" v-if="cookiesWrapper == 'are'">
                        <h3>What are cookies?</h3>
                        <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies serve different purposes, like helping us understand how a site is being used, letting you navigate between pages efficiently, remembering your preferences and generally improving your browsing experience.</p>
                        <span>If you have any questions about this Cookie Policy, please contact us by email at <a href="mailto:amura@amura.mc" style="text-decoration: none;">amura@amura.mc</a></span>
                    </div>
                    <div class="c_types" v-else-if="cookiesWrapper == 'types'">
                        <h3>What types of cookies does Amura use on the website?</h3>
                        <p>Amura uses cookies to help tailor ads off of Meta Products, measure their performance and effectiveness and support marketing and analytics. This concerns Facebook data services. You can find out more information about Facebook cookies 
                        <a href="https://www.facebook.com/privacy/policies/cookies/?entry_point=cookie_policy_redirect&entry=0" target="blank"><strong style="color:blue">here.</strong></a></p>
                        <span>If you have any questions about this Cookie Policy, please contact us by email at <a href="mailto:amura@amura.mc" style="text-decoration: none;">amura@amura.mc</a></span>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'

const cookiesBanner = ref(false)
Cookies.get('cookiesReaded') ? cookiesBanner.value = false : cookiesBanner.value = true
const cookiesWrapper = ref(null)

function changeCookiesWrapper(val) {
    if (val === cookiesWrapper.value) return cookiesWrapper.value = null
    cookiesWrapper.value = val
}

function readCookies() {
    Cookies.set('cookiesReaded', true, { expires: 365 })
    cookiesBanner.value = false
}
</script>

<style lang="scss" scoped>
    @import 'src/styles/scss/cookiesPop.scss';
</style>