import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'

import apiMixin from './mixins/api.js'
import mountedCheck from 'vue-mounted-check'

import customMobileClick from 'vue-custom-mobile-click'

const thisApp = createApp(App)

thisApp.mixin(mountedCheck)
thisApp.mixin(apiMixin)

thisApp.use(i18n)
    .use(store)
    .use(router)
    .use(customMobileClick)
    .mount('#app')
