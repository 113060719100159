<template>
    <div class="bag_item_wrapper">
        <div class="item_photo" v-if="thisProd.image" :style="{
            'background-image': `url(${thisProd.image})`
        }"></div>
        <div class="info">
            <div class="item_info_wrapper">
                <div class="name_weight_wrapper">
                    <span class="product_name">{{ thisProd.product_name }}</span>
                    <div class="item_grams">
                        <span>{{ thisProd.product_weight }}</span>
                        <span></span>
                    </div>
                </div>
            </div>
            <div class="price_row">
                <div class="item_price_wrapper">
                    <span>{{ thisProd.product_price }}€</span>
                    <small>x</small>
                    <span>{{ thisProd.product_quantity }}</span>
                </div>
                <div class="item_price_total">
                    <span>{{ getThisProductTotal(thisProd) }}€/</span>
                    <small>total</small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        thisProd: {
            type: Object,
            required: true
        }
    },
    methods: {
        getThisProductTotal(item) {
            return item.product_price * item.product_quantity;
        }
    }
}
</script>

<style>

</style>