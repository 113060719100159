import { createRouter, createWebHistory } from 'vue-router'
import mainPage from '@/views/mainPage.vue'

import homePageVue from '@/views/homePage.vue'
import shopPageVue from '@/views/shopPage.vue'
import productPageVue from '@/views/productPage.vue'
import checkoutPageVue from '@/views/checkoutPage.vue'

const routes = [
    {
        path: '/',
        name: 'mainPage',
        component: mainPage,
        children: [
            {
                path: '',
                name: 'home',
                component: homePageVue
            },
            {
                path: 'shop',
                name: 'shop',
                component: shopPageVue
            },
            {
                path: 'about',
                name: 'about',
                component: () => import(/* webpackChunkName: "aboutPage" */ '@/views/aboutPage.vue')
            },
            {
                path: 'journal',
                name: 'journal',
                component: () => import(/* webpackChunkName: "journalPage" */ '@/views/journalPage.vue')
            },
            {
                path: 'contact',
                name: 'contact',
                component: () => import(/* webpackChunkName: "contactPage" */ '@/views/contactPage.vue')
            },
            {
                path: 'recepies',
                name: 'recepies',
                component: () => import(/* webpackChunkName: "recepiesPage" */ '@/views/recepiesPage.vue')
            },
            {
                path: 'locations',
                name: 'locations',
                component: () => import(/* webpackChunkName: "locationsPage" */ '@/views/locationsPage.vue')
            },
            {
                path: 'product/:id(\\d+)',
                name: 'product page',
                component: productPageVue
            },
            {
                path: 'privacy',
                name: 'privacy policy',
                component: () => import(/* webpackChunkName: "privacyPolicy" */ '@/views/privacyPolicy.vue')
            },
            {
                path: 'checkout',
                name: 'checkout page',
                component: checkoutPageVue
            },
            {
                path: 'thank-you',
                name: 'thanks page',
                component: () => import(/* webpackChunkName: "orderPage" */ '@/views/thanksPage.vue')
            },
            {
                path: 'order/:order_id(\\S+)',
                name: 'order page',
                component: () => import(/* webpackChunkName: "orderPage" */ '@/views/orderPage.vue')
            }
        ]
    },
    {
        path: '/construction',
        name: 'construction',
        component: () => import(/* webpackChunkName: "constructionPage" */ '@/views/constructionsPage.vue')
    }
    // {
    //     path: '/login',
    //     name: 'login',
    //     component: loginPage
    // }
    // {
    //     path: '/about',
    //     name: 'about',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    // }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        } else {
            return { top: 0 }
        }
    }
})

const underConstruction = false

router.beforeEach((to, from, next) => {
    if (underConstruction) {
        if (to.name !== 'construction') {
            return next('/construction')
        }
    }
        
    return next()
})

export default router
