<template>
    <!-- <router-view v-if="!$store.state.loginData.checkingForLogin" /> -->
    <router-view />
    <cookies-pop />
</template>

<script>
import Cookies from 'js-cookie'
window.Cookies = Cookies
import device from 'current-device'
window.device = device

import vueAPI from "./modules/vueAPI.js"
import socketIoAPI from "./modules/socketAPI.js"

import cookiesPopVue from './components/cookiesPop.vue'

export default {
    name: 'App',
    components: {
        cookiesPop: cookiesPopVue
    },
    mounted() {
        vueAPI._mountedCallback(this)
        // this.checkLoginState()
    },
    beforeMount() {
        if (localStorage.getItem('lang')) {
            this.$i18n.locale = localStorage.getItem('lang')
        }
    },
    watch: {
        // '$store.state.loginData.checkingForLogin': function(newValue, oldValue) {
        //     if (newValue == false) this.checkLoginState()
        // },
        // '$store.state.loginData.adminLoggedIn': function(newValue, oldValue) {
        //     this.checkLoginState()
        // },
        // '$router.currentRoute.value.path': function() {
        //     this.checkLoginState()
        // },
        '$i18n.locale': function(newValue) {
            this.setLocalStorageLanguage(newValue)
        }
    },
    methods: {
        checkLoginState() {
            if (!this.$store.state.loginData.adminLoggedIn && !this.$store.state.loginData.checkingForLogin) {
                this.$router.push('/login')
            }
        },
        setLocalStorageLanguage(lang = false) {
            if (lang) {
                localStorage.setItem('lang', lang)
            } else {
                localStorage.setItem('lang', this.$i18n.locale)
            }
        }
    },
}
</script>

<style>
    @import url('@/styles/css/main.css');
</style>
