<template>
    <div class="bag_item_wrapper">
        <div class="item_photo" v-if="thisProd.image" :style="{
            'background-image': `url(${thisProd.image})`
        }"></div>
        <div class="info">
            <div class="item_info_wrapper">
                <div class="name_weight_wrapper">
                    <div class="name_action_buttons">
                        <span class="product_name">{{ thisProd.product_name }}</span>
                        <div class="action_wrapper">
                            <div class="action_buttons_small">
                                <div class="minus_button" @click="decrementItemQuantity(thisProd)">
                                    <i class="uil uil-minus-circle"></i>
                                </div>
                                <span class="items_quantity">{{ thisProd.product_quantity }}</span>
                                <div class="plus_button" @click="increcementItemQuantity(thisProd)">
                                    <i class="uil uil-plus-circle"></i>
                                </div>
                            </div>
                            <div class="remove_button" title="Remove item" @click="removeItem(thisProd)">
                                <i class="uis uis-trash"></i>
                            </div>
                        </div>
                    </div>
                    <div class="item_grams">
                        <span>{{ thisProd.product_weight }}</span>
                        <span></span>
                    </div>
                </div>
            </div>
            <div class="price_row">
                <div class="item_price_wrapper">
                    <span>{{ thisProd.product_price }}€/</span>
                    <small>item</small>
                </div>
                <div class="item_price_total">
                    <span>{{ getThisProductTotal(thisProd) }}€/</span>
                    <small>total</small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        thisProd: {
            type: Object,
            required: true
        }
    },
    methods: {
        increcementItemQuantity(item) {
            if (item.max_quantity && item.product_quantity >= item.max_quantity) {
                if (item.product_quantity > item.max_quantity) {
                    item.product_quantity = item.max_quantity;
                }
                return;
            }
            item.product_quantity += 1;
        },
        decrementItemQuantity(item) {
            if (item.product_quantity > 1) {
                item.product_quantity -= 1;
            }
        },
        removeItem(item) {
            this.$store.dispatch('removeCartItem', item);
        },
        getThisProductTotal(item) {
            return item.product_price * item.product_quantity;
        }
    }
}
</script>

<style>

</style>