<template>
    <router-view/>
</template>

<script>
export default {
    name: 'panelMain',
    data() {
        return {}
    }
}
</script>

<style>
    @import url('@/styles/css/panelMain.css');
</style>