<template>
    <div class="product_page_wrapper" v-if="!dataLoading">
        <div class="breadcrumbs_wrappper">
            <router-link to="/shop">
                <i class="uis uis-arrow-left"></i>
                <small><strong>CAVIAR</strong></small>
            </router-link>
        </div>
        <div class="product_image_wrapper">
            <div class="product_image" v-for="thisImage in productData.images" :key="thisImage" :style="{
                'background-image': `url(${thisImage})`
            }"></div>
        </div>
        <div class="product_info_wrapper">
            <div class="name_and_description">
                <h5>{{ productData.name }}</h5>
                <p>{{ selectLanguageFromData(productData.descriptions) }}</p>
            </div>
            <div class="more_info_wrapper">
                <div class="storage">
                    <span><strong>{{ $t('storage') }}:</strong></span>
                    <span>{{ selectLanguageFromData(productData.storage) }}</span>
                </div>
                <div class="origin">
                    <div class="origin_wrapper">
                        <span><strong>{{ $t('origin') }}:</strong></span>
                        <div class="discription_variations">
                            <span>{{ selectLanguageFromData(productData.origin)[0] }}</span>
                            <span>{{ selectLanguageFromData(productData.origin)[1] }}</span>
                        </div>
                    </div>
                </div>
                <div class="size">
                    <span><strong>{{ $t('size') }}:</strong></span>
                    <span>{{ selectLanguageFromData(productData.size) }}</span>
                </div>
            </div>
            <div class="add_to_cart_section">
                <div class="size_selection_wrapper">
                    <span><strong>{{ $t('select_size') }}:</strong></span>
                    <div class="bulck_size_price_selection">
                        <div class="start_selection" @click="selectingProductSize = !selectingProductSize">
                            <template v-for="(thisSize) in productVariables.size" :key="thisSize.id">
                                <li class="selection_wrapper" v-if="thisSize.id == selectedProductData.size">
                                    <div class="serves">
                                        <span>{{ thisSize.name }}</span>
                                        <small>{{ thisSize.additionalInfo }}</small>
                                    </div>
                                    <div class="price">
                                        <span>{{ thisSize.price }}</span>
                                        <small>€</small>
                                    </div>
                                    <i class="uis uis-direction"></i>
                                </li>
                            </template>
                        </div>

                        <div class="dropdown_selection_menu" :class="{
                            'opened': selectingProductSize
                        }">
                            <template v-for="(thisSize) in productVariables.size" :key="thisSize.id">
                                <li class="selection_wrapper" v-if="thisSize.id != selectedProductData.size" @click="selectProductSize(thisSize.id)">
                                    <div class="serves">
                                        <span>{{ thisSize.name }}</span>
                                        <small>{{ thisSize.additionalInfo }}</small>
                                    </div>
                                    <div class="price">
                                        <span>{{ thisSize.price }}</span>
                                        <small>€</small>
                                    </div>
                                </li>
                            </template>
                        </div>

                        <div class="quantity_selection_with_button">
                            <div class="selector_arrow_wrapper">
                                <select v-model="selectedProductData.quantity">
                                    <option v-for="thisNumber in productVariables.maxQuantity" :key="thisNumber">{{ thisNumber }}</option>
                                </select>
                                <i class="uil uil-angle-down"></i>
                            </div>
                            <button class="add_to_cart_button" @click="addProductToCart()">
                                <span>{{ $t('add_to_cart') }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="delivery_info_block_wrapper">
        <div class="text_icon_wrapper">
            <i class="uit uit-shield-check"></i>
            <span class="special_text">{{ $t('secure_payment') }}</span>
        </div>
        <div class="text_icon_wrapper">
            <i class="uit uit-truck"></i>
            <span class="special_text">
                {{ $t('delivery_promo_text') }}
            </span>
        </div>
        <div class="text_icon_wrapper_borders">
            <i class="uit uit-box"></i>
            <span class="special_text">{{ $t('refrigerated_packaging') }}</span>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.api_getProducts(1, 1, {
            "ids": [this.$route.params.id],
            "fullData": true
        }).then((response) => {
            if (response.response && response.products.length == 1) {
                let thisProduct = response.products[0];

                for (const key in thisProduct.data.origin) {
                    let thisOrigin = thisProduct.data.origin[key];
                    let originSplit = thisOrigin.split("||");
                    originSplit.forEach((thisOriginSplit) => {
                        thisOriginSplit = thisOriginSplit.trim();
                    })

                    if (originSplit.length != 2) {
                        originSplit = [thisOrigin[0], ""];
                    }

                    thisProduct.data.origin[key] = originSplit;
                }

                thisProduct.images = thisProduct.images.map((thisImage) => {
                    return thisImage.image_url;
                })

                this.productData = {
                    id: thisProduct.id,
                    name: thisProduct.name,
                    images: thisProduct.images || [],
                    descriptions: thisProduct.descriptions,
                    storage: thisProduct.data.storage,
                    origin: thisProduct.data.origin,
                    size: thisProduct.data.size,
                    originDescription: this.productData.originDescription
                }

                let sizeVariatons = [];
                thisProduct.variations.forEach((thisVariation) => {
                    sizeVariatons.push({
                        id: thisVariation.id,
                        name: thisVariation.name,
                        additionalInfo: thisVariation.additional_text,
                        price: Number(thisVariation.price)
                    })
                })

                this.selectedProductData.size = sizeVariatons[0].id;


                this.productVariables.size = sizeVariatons
            } else {
                this.$router.push({
                    name: "404"
                })
                console.warn("Product not found");
            }

            this.dataLoading = false;
        });
    },
    data() {
        return {
            dataLoading: true,
            dataError: false,
            selectedProductData: {
                size: 1,
                quantity: 1
            },
            selectingProductSize: false,
            productData: {
                id: 1,
                name: 'Name loading...',
                image: null,
                descriptions: {
                    'en': 'Description loading...'
                },
                storage: {
                    'en': 'Storage info loading...',
                },
                origin: {
                    'en': 'Origin info loading...',
                },
                size: {
                    'en': 'Size info loading...',
                }
            },
            productVariables: {
                size: [
                    {
                        id: 1,
                        name: 'Loading...',
                        additionalInfo: '',
                        price: 0
                    }
                ],
                maxQuantity: 9
            }
        }
    },
    methods: {
        selectProductSize(size_id) {
            this.selectedProductData.size = size_id
            this.selectingProductSize = false
        },
        selectLanguageFromData(data) {
            return data[this.$i18n.locale] || data['en']
        },
        addProductToCart() {
            let thisVariationData = this.productVariables.size.find((thisVariation) => {
                return thisVariation.id == this.selectedProductData.size
            })
            if (!thisVariationData) {
                console.warn("Variation not found");
                return;
            }

            this.$store.commit('addCartItem', {
                id: this.productData.id,
                image: this.productData.image,
                variation_id: thisVariationData.id,
                name: this.productData.name,
                price: thisVariationData.price,
                quantity: Number(this.selectedProductData.quantity),
                weight: thisVariationData.name,
                max_quantity: this.productVariables.maxQuantity
            })
        }
    }
}
</script>

<style>
     @import url('@/styles/css/productPage.css');
</style>