let vueAPI = {
    _thisVueObj: null,
    _mounted: false,
    _mountedCallbacks: [],
    onMounted(callback) {
        if (typeof callback === 'function') {
            if (this._mounted) {
                callback(this._thisVueObj);
            } else {
                this._mountedCallbacks.push(callback);
            }
        }
    },
    _mountedCallback(thisVue) {
        this._mounted = true;
        this._thisVueObj = thisVue;
        this._mountedCallbacks.forEach(callback => callback(thisVue));
        this._mountedCallbacks = [];
    }
}

export default vueAPI;