<template>
    <div class="local-switcher-wrapper">
        <select @change="switchLocaleEvent">
            <option v-for="thisLocale in locales" :key="thisLocale"
            :value="thisLocale"
            :selected="thisLocale == $i18n.locale">{{ thisLocale }}</option>
        </select>
    </div>
</template>

<script>
export default {
    data() {
        return {
            locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALES.split(','),
        }
    },
    methods: {
        switchLocaleEvent($event) {
            if (!$event.target || !$event.target.value) return;

            this.switchLocale($event.target.value);
        },
        switchLocale(locale) {
            if (this.$i18n.locale !== locale) {
                this.$i18n.locale = locale;
            }
        }
    },
}
</script>

<style>
    @import url('@/styles/css/localesSwitcher.css');
</style>