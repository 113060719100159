<template>
    <div class="checkout_page_wrapper">
        <div class="first_info">
            <div class="checkout_info_wrapper">
                <h6>PLACE YOUR DELIVERY</h6>
                <p>We invite you to place your order for delivery with Amura. Please share your contact information with us and we will get back to you very shortly to schedule the delivery at your door.
                We will happily accept credit card payments, cash and checks.</p>
            </div>

            <div class="summary_checkout_info mobile">
                <h6>Order Summary</h6>
                <div class="order_wrapper_info">
                    <div class="total_items">
                        <span>total items:</span>
                        <span>(&nbsp;<strong>{{ shoppingCartElCount }}</strong>&nbsp;)</span>
                    </div>
                    <div class="items_information_wrap">
                        <checkout-item v-for="(thisProd, thisProdIdx) in $store.state.myCart.cartData" :key="`${thisProd.product_id}_${thisProdIdx}`"
                        :thisProd="thisProd"></checkout-item>
                        <div class="subtotal_wrapper">
                            <div class="fixed_position">
                                <div class="subtotal_price">
                                    <small>subtotal</small>
                                    <span>{{ shoppingCartSubtotal }}€</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="fill_form">
                <form @submit.prevent="sendOrder">
                    <div class="gender_row">
                        <li>Personal information</li>
                        <div class="gender_wrapper">
                            <div class="input_wrap">
                                <input type="radio" name="gender" id="mr" required value="Mr">
                                <label for="mr">Mr</label>
                            </div>
                            <div class="input_wrap">
                                <input type="radio" name="gender" id="ms" required value="Ms">
                                <label for="ms">Ms</label>
                            </div>
                            <div class="input_wrap">
                                <input type="radio" name="gender" id="miss" required value="Miss">
                                <label for="miss">Miss</label>
                            </div>
                        </div>
                    </div>

                    <div class="first_section_wrapper">
                        <label for="name">First Name</label>
                        <input type="text" id="name" required name="firstname">
                        <label for="surename">Last Name</label>
                        <input type="text" id="surename" required name="lastname">
                        <label for="email">E-mail</label>
                        <input type="email" id="email" required name="email">
                        <label for="tel">Phone number</label>
                        <input type="tel" id="tel" required name="phonenumber">
                        <small>We require a phone number to get in touch with you at the moment of the delivery  </small>
                    </div>

                    <div class="second_section_wrapper">
                        <li>Delivery  Address</li>

                        <label for="country">Country</label>
                        <div class="country_selector_wrapper">
                            <select name="deliveryCountryId" id="country">
                                <option value="145" selected>Monaco</option>
                            </select>
                            <i class="uil uil-angle-down"></i>
                        </div>

                        <small>If you wish to place a delivery to another city or country, please get in touch with us by email: <a href="mailto:amura@amura.mc">amura@amura.mc</a> or call or call <a href="tel:+33661102587">+33 6 61 10 25 87</a></small>

                        <div class="third_section">
                            <label for="address">Address line</label>
                            <input type="text" id="address" required name="deliveryAddress">
                            
                            <label class="not_required" for="info">Additional information </label>
                            <textarea id="info" rows="4" name="additionalInfo"></textarea>
                        </div>

                        <div class="confiramation_sectin">
                            <div class="policy_confirmation">
                                <div class="confirmation_wrap">
                                    <span>I confirm that I have read Privacy Policy</span>
                                    <div class="confirm_checkboxes">
                                        <div class="block">
                                            <input type="checkbox" id="yes" name="confirmation" v-model="privacyPolicyConfirmed">
                                            <label class="not_required" for="yes">Yes</label>
                                        </div>
                                    </div>
                                </div>
                                <div id="checkout_captcha"></div>
                                <button type="submit" :disabled="!canSendOrder" v-mobile-touch>Pay now</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="summary_checkout_info desctop">
            <h6>Order Summary</h6>
            <div class="order_wrapper_info">
                <div class="total_items">
                    <span>total items:</span>
                    <span>(&nbsp;<strong> {{ shoppingCartElCount }} </strong>&nbsp;)</span>
                </div>
                <div class="items_information_wrap">
                    <checkout-item v-for="(thisProd, thisProdIdx) in $store.state.myCart.cartData" :key="`${thisProd.product_id}_${thisProdIdx}`"
                    :thisProd="thisProd"></checkout-item>
                    <div class="subtotal_wrapper">
                        <div class="fixed_position">
                            <div class="subtotal_price">
                                <small>subtotal</small>
                                <span>{{ shoppingCartSubtotal }}€</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import $ from 'jquery';

import checkoutItemVue from '@/components/checkoutItem.vue'

let confirmOnPageLeave = false;

export default {
    components: {
        checkoutItem: checkoutItemVue
    },
    mounted() {
        if (this.shoppingCartElCount < 1) {
            return this.$router.go(-1)
        }

        window.reCaptchaCallbackLoaded(() => {
            window.grecaptcha.render(
                'checkout_captcha',
                {
                    sitekey: '6LfzRNIhAAAAANg4ya0kqX4tvVRzBkN5vGP_FLwN',
                    theme: 'light',
                    callback: this.recaptchaCallback,
                    'expired-callback': this.recaptchaExpiredCallback,
                    'error-callback': this.recaptchaErrorCallback
                }
            )
        })

        confirmOnPageLeave = true;
    },
    data() {
        return {
            privacyPolicyConfirmed: false,
            grecaptchaConfirmed: false
        }
    },
    methods: {
        recaptchaCallback() {
            this.grecaptchaConfirmed = true
        },
        recaptchaExpiredCallback() {
            this.grecaptchaConfirmed = false
        },
        recaptchaErrorCallback() {
            this.grecaptchaConfirmed = false
        },
        sendOrder(submitData) {
            confirmOnPageLeave = false;
            let thisFormData = {}
            $(submitData.target).serializeArray().forEach((thisInput) => {
                // console.log(thisInput)
                thisFormData[thisInput.name] = thisInput.value
            })
            this.api_sendOrderFromCart(thisFormData).then((data) => {
                if (data.response == 1) {
                    this.$store.state.myCart.cartData = []
                    this.$router.replace(`/order/${data.order_id}`)
                }
            })
        }
    },
    computed: {
        shoppingCartElCount() {
            let productCount = 0
            this.$store.state.myCart.cartData.forEach(thisItem => {
                productCount += thisItem.product_quantity
            })
            return productCount
        },
        shoppingCartSubtotal() {
            let subtotal = 0
            this.$store.state.myCart.cartData.forEach(thisItem => {
                subtotal += thisItem.product_price * thisItem.product_quantity
            })

            subtotal = Math.round((subtotal - (subtotal * 0.15)) * 100) / 100; // black-friday-discount

            return subtotal.toFixed(2)
        },
        canSendOrder() {
            return this.privacyPolicyConfirmed && this.grecaptchaConfirmed
        }
    },
    beforeRouteLeave(to, from, next) {
        if (!confirmOnPageLeave) return next()

        if (device.mobile() || device.ipad()) {
            next()
        } else {
            window.confirm('Are you sure you want to leave? You will lose checkout progress') ? next() : next(false)
        }
    }
}
</script>

<style>
    @import url('@/styles/css/checkoutPage.css');
</style>