import vueAPI from "@/modules/vueAPI.js"
import io from 'socket.io-client'
import socketIoAPI from '@/modules/socketAPI.js'

let thisVueSocket = {
    state: {
        hasBeenReconnected: false,
        connectedUsers: {}
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
}

vueAPI.onMounted(function(thisVue) {
    // configureNewConnection()

    function configureNewConnection() {
        if (socket) socket.offAny()
        socket = io("wss://socket.promiseshop.eu:3001/admin", {
            reconnection: false,
        });
        socketIoAPI._callAllCallbacks(socket)
        
        socket.on('connect_error', (err) => {
            // console.log(`Error: ${err}`)
            vueAPI.onMounted(function(thisVue) {
                if (thisVue.$store.state.loginData.adminLoggedIn) {
                    thisVue.$store.state.socket.hasBeenReconnected = true
                }
            })
            console.error(`Error connection to server!`)
            console.log('Reconnect in 5 seconds...')
            setTimeout(() => {
                console.log('Reconnecting...')
                configureNewConnection()
            }, 5000)
        })
        
        socket.on('disconnect', () => {
            vueAPI.onMounted(function(thisVue) {
                if (thisVue.$store.state.loginData.adminLoggedIn) {
                    thisVue.$store.state.socket.hasBeenReconnected = true
                }
            })
            console.warn('Disconnected from server!');
            console.log('Reconnecting...');
            configureNewConnection()
        })
        
        socket.on('ping', (callbackFuction) => {
            let timestampNow = Date.now()
            if (typeof callbackFuction === 'function') {
                callbackFuction(timestampNow)
            }
        })
    }
})

export default thisVueSocket