<template>
    
    <panel-header></panel-header>
    <panel-main></panel-main>
    <panel-futer></panel-futer>
    
</template>

<script>
import panelHeaderVue from '@/views/panelHeader.vue'
import panelMainVue from '@/views/panelMain.vue'
import panelFuterVue from '@/views/panelFuter.vue'

export default {
    name: 'mainPage',
    components: {
        panelHeader: panelHeaderVue,
        panelMain: panelMainVue,
        panelFuter: panelFuterVue
    },
    data() {
        return {}
    },
    mounted() {
        // if (!this.$store.state.loginData.adminLoggedIn) {
        //     this.$router.push('/login');
        // }
    }
}
</script>

<style>
    @import url('@/styles/css/mainPage.css');
</style>