let socket = null
global.socket = socket
let socketIoAPI = {
    _allCallbacks: [],
    onNewConnection(callback) {
        if (typeof callback !== 'function') {
            return
        }

        this._allCallbacks.push(callback)
    },
    _callAllCallbacks(socketVar) {
        this._allCallbacks.forEach(callback => {
            if (typeof callback === 'function') callback(socketVar)
        })
    }
}

// vueAPI.vueDataObj.components.devPanel = {
//     selectedMenu: false,
//     sockets: {
//         hasBeenReconnected: false,
//         connectedUsers: {}
//     }
// }


export default socketIoAPI